import style from '../styles/Prodotti.module.css'
import {Trans, useTranslation} from "react-i18next";

export default function Prodotti() {
    const { t, i18n } = useTranslation();

    return (
        <div className={style.mainContainer}>
            <div className={style.contentContainer}>
                <p className={style.title}><Trans i18nKey="vini.titolo" components={{br: <br/>}}/></p>
                <div className={style.productContainer}>
                    <div className={style.productContentContainer}>
                        <p className={style.productTitle}>
                            ZENA <br/>
                            <span style={{fontWeight:'normal'}}>
                               Trebbiano Marche <br/>
                                <Trans i18nKey="vini.indicazione-vino-1" components={{br: <br/>}}/> <br/>
                            </span>

                        </p>
                        <p className={style.productDescription}>
                            <Trans i18nKey="vini.descrizione-vino-1" components={{br: <br/>, em:<em />}}/>
                        </p>
                    </div>
                    <img src={'/images/Az_Agr_Sciauerta_bottiglia_DEF.png'} alt={'bottiglia img'}
                         className={style.productImage} />
                </div>
            </div>
        </div>
    )
}