
import style from '../styles/ProgettiPage.module.css';
import {Trans, useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {faCircleArrowLeft, faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect} from "react";

function ProgettiPage() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate(); // Inizializza useNavigate
    const { pathname } = useLocation();
    const location = useLocation();

    useEffect(() => {
        if (pathname !== "/") {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, []);

    return(
        <div className={style.mainContainer}>
            <div style={{position:'absolute', top:'20px', left:'20px', cursor:'pointer', zIndex:'5'}} onClick={()=>{
                const from = location.state?.from || "#landing";
                navigate('/'+ from)
            }}>
                <FontAwesomeIcon icon={faCircleArrowLeft}  color="#fff" fontSize={'25px'}/>
            </div>
            <div className={style.imageContainer}>
                <div className={style.backdrop}>

                </div>
                <h1 className={style.imageText}>
                    <Trans i18nKey="progetti.contenuto1" components={{br: <br/>}}/>
                </h1>
            </div>

            <div className={style.contentContainer}>
                <p className={style.contentText}>
                    <Trans i18nKey="progetti.contenuto2" components={{br: <br/>}}/>
                </p>
            </div>
        </div>
    )
}

export default ProgettiPage;