import {Trans, useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
import "../styles/Storia.css"
import {useNavigate} from "react-router-dom";

function Landing() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate(); // Inizializza useNavigate

    const handleNavigation = (path) => {
        navigate(path, { state: { from: "#storia" } }); // Naviga verso /storia
    };

    return (
        <div className="storia-main-container">
            <div className="storia-content-container">
                <h1 className="storia-title">
                    <Trans i18nKey="storia.titolo" components={{ br: <br /> }} />
                </h1>
                <p className="storia-subtitle">{t('storia.sottotitolo')}</p>
                <div className="storia-action-container" onClick={()=>handleNavigation('/storia')}>
                    <h2 className="storia-action-text">{t('storia.action')}</h2>
                    <FontAwesomeIcon icon={faCircleArrowRight} size="xl" color="#13220d"/>
                </div>
            </div>
        </div>
    )
}

export default Landing;