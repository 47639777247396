import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import {styled} from "@mui/material";

const StyledSwiper = styled(Swiper)`
  width: 500px;
  height: 350px;

  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;



const ImageCarousel = () => {
    const images = ['cantina-1.jpg','cantina-2.jpg','cantina-3.jpg', 'cantina-4.jpg']

    return (
        <StyledSwiper
            modules={[Navigation]}
            navigation
            loop
        >
            {images.map((image, index) => (
                <SwiperSlide key={index}>
                    <img
                        src={`/images/cantina/${image}`}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '25px',
                        }}
                        alt={`Slide ${index}`}
                    />
                </SwiperSlide>
            ))}
        </StyledSwiper>
    );
};

export default ImageCarousel;
