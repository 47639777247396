import style from '../styles/Cantina.module.css'
import {Trans, useTranslation} from "react-i18next";
import ImageCarousel from "./ImageCarousel";
import {useNavigate} from "react-router-dom";

export default function Cantina() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate(); // Inizializza useNavigate

    const handleNavigation = (path) => {
        navigate(path, { state: { from: "#cantina" } }); // Naviga verso /storia
    };

    return (
        <div className={style.mainContainer}>
            <div className={style.contentContainer}>
                <p className={style.title}><Trans i18nKey="cantina.titolo" components={{br: <br/>}}/></p>
                <div className={style.rowContainer}>
                    <div className={style.textContainer}>
                        <p className={style.innerTitle}>
                            <Trans i18nKey="cantina.sottotitolo" components={{br: <br/>}}/> <br/>
                        </p>
                        <p className={style.description}>
                            <Trans i18nKey="cantina.contenuto1" components={{br: <br/>}}/>
                        </p>
                        <p className={style.description}>
                            <Trans i18nKey="cantina.contenuto2" components={{br: <br/>}}/>
                        </p>
                        <p className={style.action} onClick={()=>handleNavigation('/visita')} style={{cursor:'pointer', textDecoration:'underline'}}>
                            <Trans i18nKey="cantina.action" components={{br: <br/>}}/>
                        </p>
                    </div>
                    <div className={style.carouselContainer}>
                        <ImageCarousel  />
                    </div>

                    {/*<img src={'/images/Az_Agr_Sciauerta_bottiglia_DEF.png'} alt={'bottiglia img'}
                         className={style.image}/>*/}
                </div>
            </div>
        </div>
    )
}