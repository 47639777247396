import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import style from "../styles/BannerVisita.module.css";

export default function BannerVisita() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate("/visita", { state: { from: "#banner" } });
    };

    return (
        <div onClick={handleNavigation} className={style.bannerContainer}>
            <div className={style.marqueeContent}>
                {[...Array(20)].map((_, i) => (
                    <p key={i} className={style.marqueeItem}>
                        <Trans i18nKey="cantina.action" components={{ br: <br /> }} />
                    </p>
                ))}
            </div>
        </div>
    );
}
