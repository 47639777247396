import {Trans, useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";

import territorioStyle from "../styles/Territorio.module.css";
import {useNavigate} from "react-router-dom";


function Territorio() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate(); // Inizializza useNavigate

    const handleNavigation = (path) => {
        navigate(path, { state: { from: "#territorio" } }); // Naviga verso /storia
    };

    return (
        <div className={territorioStyle.mainContainer}>
            <div className={territorioStyle.contentContainer}>
                <h1 className={territorioStyle.title}>
                    <Trans i18nKey="territorio.titolo" components={{br: <br/>}}/>
                </h1>
                <p className={territorioStyle.subtitle}>{t('territorio.sottotitolo')}</p>
                <div className={territorioStyle.actionContainer} onClick={() => handleNavigation('/territorio')}>
                    <h2 className={territorioStyle.actionText}>{t('action')}</h2>
                    <FontAwesomeIcon icon={faCircleArrowRight} size="xl" color="#fff"/>
                </div>
            </div>
        </div>
    )
}

export default Territorio;