import Landing from "../components/Landing";
import Storia from "../components/Storia";
import Territorio from "../components/Territorio";
import Progetti from "../components/Progetti";
import Prodotti from "../components/Prodotti";
import Contatti from "../components/Contatti";
import Cantina from "../components/Cantina";
import BannerVisita from "../components/BannerVisita";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

function Homepage() {
    const { hash } = useLocation();

    useEffect(() => {
        console.info(hash)
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [hash]);

    return (
        <div style={{overflowX: "hidden"}}>
            <div id="landing">
                <Landing/>
            </div>
            <div id="storia">
                <Storia/>
            </div>
            <div id="territorio">
                <Territorio/>
            </div>
            <div id="progetti">
                <Progetti/>
            </div>
            <div id="cantina">
                <Cantina/>
            </div>
            <div id="prodotti">
                <Prodotti/>
            </div>
            <div id="banner">
                <BannerVisita/>
            </div>
            <div id="contatti">
                <Contatti/>
            </div>
        </div>
    )
}

export default Homepage;