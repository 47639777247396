import style from '../styles/Visita.module.css'
import {Trans, useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function Visita() {
    const { pathname } = useLocation();
    const location = useLocation();

    useEffect(() => {
        if (pathname !== "/") {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, []);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate(); // Inizializza useNavigate

    const ita_link = 'https://docs.google.com/forms/d/e/1FAIpQLSe52kDPqMfK1snsYG-Py_CkPDpPd8Xdo5IYnIe3JvoiW9rg0w/viewform?embedded=true';
    const eng_link = 'https://docs.google.com/forms/d/e/1FAIpQLSdvxS52rMn1hMNuH-ZNtRurmie0FiVbV6H7E7PjJVCkcRr-3Q/viewform?embedded=true';
    const ger_link = 'https://docs.google.com/forms/d/e/1FAIpQLSdyni1d_3QLExvHchZWnJ9W9oSWBGY25Ya6XThf3fHRP38_3A/viewform?embedded=true';

    let formLink = ita_link;
    if (i18n.language === 'en') {
        formLink = eng_link;
    } else if (i18n.language === 'de') {
        formLink = ger_link;
    }

    return (
        <div className={style.container}>
            <div style={{position: 'fixed', top: '20px', left: '10px', cursor: 'pointer', zIndex: '1000'}}
                 onClick={() => {
                     const from = location.state?.from || "#landing";
                     navigate('/'+ from)
                 }}>
                <FontAwesomeIcon icon={faCircleArrowLeft} className={'st-page-back'} color="#fff" fontSize={'30px'}/>
            </div>
            <p className={style.title}>
                <Trans i18nKey="visite.titolo" components={{br: <br/>}}/>
            </p>
            <div className={style.innerContainer}>

                <div className={style.textContainer}>
                    <p>
                        <Trans i18nKey="visite.contenuto1" components={{br: <br/>}}/>
                    </p>
                    <p>
                        <Trans i18nKey="visite.contenuto2" components={{br: <br/>}}/>
                    </p>
                    <p>
                        <Trans i18nKey="visite.contenuto3" components={{br: <br/>}}/>
                    </p>
                </div>

                <iframe
                    src={formLink}
                    className={style.iframe}
                    allowFullScreen
                    title="Google Form"
                ></iframe>
            </div>

        </div>
    );
}